define("ember-svg-jar/inlined/union", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.533 5.335c.248.248.574.374.9.374v.002c.325 0 .651-.126.9-.374l2.082-2.082c.906-.906 2.484-.798 3.52.238.307.307.537.666.683 1.065l.026.065c.298.89.122 1.798-.468 2.388l-3.67 3.67c-.82.82-2.223.816-3.263-.011a1.274 1.274 0 00-1.585 1.994 5.322 5.322 0 003.306 1.174l.002-.003c1.236 0 2.44-.455 3.343-1.357l3.669-3.669c1.311-1.311 1.71-3.276 1.035-5.126a1.298 1.298 0 00-.037-.091 5.449 5.449 0 00-1.24-1.9c-2.029-2.029-5.223-2.137-7.121-.239L9.533 3.535a1.273 1.273 0 000 1.8zm-3.86 14.798c-.077 0-.154 0-.233-.006a5.37 5.37 0 01-3.552-1.59 5.446 5.446 0 01-1.275-1.991c-.675-1.85-.276-3.815 1.035-5.127l3.67-3.668c1.736-1.738 4.593-1.815 6.648-.182a1.274 1.274 0 01-1.585 1.994c-1.04-.827-2.443-.831-3.264-.01L3.448 13.22c-.589.59-.765 1.495-.47 2.382.01.022.018.045.027.068.146.399.376.758.683 1.065a2.832 2.832 0 001.867.846c.647.029 1.234-.186 1.653-.606l2.082-2.082a1.273 1.273 0 011.8 1.8l-2.082 2.082c-.879.88-2.054 1.355-3.334 1.355v.002z\" fill=\"#00CAFF\"/>",
    "attrs": {
      "width": "21",
      "height": "21",
      "viewBox": "0 0 21 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});