define("ember-svg-jar/inlined/send", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_2518_2442)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.592 16.177l5.7-15.469-15.469 5.7 6.513 3.256 3.256 6.513zm-2.89-7.821L4.175 6.593l8.373-3.085L7.7 8.356zm5.79-3.905l-3.085 8.373-1.763-3.526 4.847-4.847z\"/></g>",
    "attrs": {
      "width": "17",
      "height": "17",
      "viewBox": "0 0 17 17",
      "xmlns": "http://www.w3.org/2000/svg",
      "class": "o--icon"
    }
  };
});