define("ember-svg-jar/inlined/Later", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23.77 15.334L16.104 23l7.666 7.667-7.666 7.666L.77 23 16.104 7.667l7.666 7.667zM23.77 15.334L31.438 23l-7.667 7.667 7.667 7.666L46.77 23 31.438 7.667l-7.667 7.667z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "47",
      "height": "46",
      "viewBox": "0 0 47 46",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});