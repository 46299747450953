define("ember-svg-jar/inlined/nav-up-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_2468_53524)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#64727C\"><path d=\"M.843 7.057a.617.617 0 00.894 0l4.021-4.021L9.78 7.057a.617.617 0 00.894 0 .617.617 0 000-.893l-4.469-4.47a.617.617 0 00-.893 0l-4.47 4.47a.69.69 0 00-.19.447c0 .127.063.319.19.446z\"/><path d=\"M.843 10.838a.617.617 0 00.894 0l4.021-4.022 4.022 4.022a.617.617 0 00.894 0 .617.617 0 000-.894L6.205 5.476a.617.617 0 00-.893 0L.842 9.944a.69.69 0 00-.19.447c0 .128.063.32.19.447z\"/></g><defs><clipPath id=\"clip0_2468_53524\"><path fill=\"#fff\" transform=\"matrix(0 1 1 0 .259 .767)\" d=\"M0 0h11v11H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});