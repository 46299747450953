define("ember-svg-jar/inlined/sc-story", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#a)\"><path fill-rule=\"evenodd\" d=\"M3.115 2.34c-.038.018-.198.098-.198.422v17.81c0 .323.16.403.198.422.01.005.012.006 0 .006h7.264c-.012 0-.01 0 0-.006.038-.019.198-.099.198-.422V2.762c0-.324-.16-.404-.198-.423-.01-.005-.012-.006 0-.006H3.115c.012 0 .01.001 0 .006zm-2.532.422C.583 1.213 1.74 0 3.115 0h7.264c1.376 0 2.532 1.213 2.532 2.762v17.81c0 1.548-1.156 2.761-2.532 2.761H3.115c-1.376 0-2.532-1.213-2.532-2.761V2.762zm17.038 4.244c-.038.019-.198.098-.198.423v17.81c0 .323.16.403.198.422.01.005.012.006 0 .006h7.264c-.012 0-.01-.001 0-.006.038-.02.198-.099.198-.423V7.428c0-.323-.16-.403-.198-.422-.01-.005-.012-.006 0-.006h-7.264c.012 0 .01 0 0 .006zm-2.532.423c0-1.55 1.155-2.762 2.532-2.762h7.264c1.376 0 2.532 1.213 2.532 2.762v17.81c0 1.548-1.156 2.761-2.532 2.761h-7.264c-1.377 0-2.532-1.213-2.532-2.762V7.428z\" clip-rule=\"evenodd\"/></g><defs><clipPath id=\"a\"><path d=\"M0 0h28v28H0z\"/></clipPath></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});