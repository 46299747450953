define("ember-svg-jar/inlined/social-x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.972 4.45L10.775.03h-.901L6.572 3.868 3.934.03H.892l3.989 5.804L.892 10.47h.902L5.28 6.417l2.785 4.053h3.042L6.972 4.45zM5.738 5.886l-.405-.578L2.118.708h1.384L6.097 4.42l.404.578 3.373 4.824H8.49L5.738 5.886z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "#fff",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});