define("ember-svg-jar/inlined/collaborator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.345 7.997A5.632 5.632 0 0012.51 6.8a3.4 3.4 0 001.265-2.647A3.407 3.407 0 0010.37.75a.583.583 0 100 1.167 2.237 2.237 0 010 4.475.583.583 0 100 1.166c1.194 0 2.317.453 3.16 1.275a4.252 4.252 0 011.303 3.06c0 .608-.172.822-.302.93-.216.181-.598.291-1.199.345a.584.584 0 00.052 1.165c.017 0 .035 0 .053-.002.86-.078 1.429-.267 1.842-.611.486-.405.721-1.003.72-1.827a5.413 5.413 0 00-1.654-3.896z\" fill=\"#64727C\"/><path d=\"M10.136 9.15a5.94 5.94 0 00-1.997-1.286 3.574 3.574 0 001.38-2.82A3.582 3.582 0 005.94 1.466a3.581 3.581 0 00-3.578 3.577 3.574 3.574 0 001.379 2.821 5.942 5.942 0 00-1.997 1.285A5.696 5.696 0 000 13.26c0 .718.214 1.294.637 1.713.436.434 1.072.69 2 .804.754.093 1.641.088 2.581.082a118.274 118.274 0 012.178.004c.67 0 1.3-.014 1.857-.083.923-.113 1.556-.367 1.991-.8.423-.42.638-.999.637-1.72 0-1.554-.62-3.014-1.745-4.11zM3.529 5.043a2.411 2.411 0 114.823 0 2.411 2.411 0 01-4.823 0zm6.893 9.109c-.566.562-2.098.551-3.72.54a120.142 120.142 0 00-1.49 0c-1.638.01-3.184.019-3.754-.547-.125-.124-.291-.352-.291-.886 0-1.236.493-2.399 1.392-3.274A4.814 4.814 0 015.94 8.62c1.279 0 2.479.485 3.38 1.364a4.548 4.548 0 011.395 3.275c0 .258-.03.631-.293.892z\" fill=\"#64727C\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});