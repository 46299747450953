define("ember-svg-jar/inlined/custom-analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_19475_5878)\"><path d=\"M11.303 13.358v3.082M13.872 14.385v2.055M16.44 11.303v5.137\" stroke=\"#323B43\" stroke-width=\"1.85\" stroke-linecap=\"round\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 14c0-.638.518-1.156 1.156-1.156h1.713a1.156 1.156 0 010 2.312H1.155A1.156 1.156 0 010 14zM4.1 4.1a1.156 1.156 0 011.635 0l1.212 1.212a1.156 1.156 0 01-1.635 1.635L4.1 5.735a1.156 1.156 0 010-1.635zM14 0c.638 0 1.156.518 1.156 1.156v1.713a1.156 1.156 0 01-2.312 0V1.155C12.844.518 13.362 0 14 0zM23.9 4.1a1.156 1.156 0 010 1.635l-1.212 1.212a1.156 1.156 0 01-1.635-1.635L22.265 4.1a1.156 1.156 0 011.635 0zM23.976 14c0-.638.517-1.156 1.155-1.156h1.713a1.156 1.156 0 110 2.312h-1.713A1.156 1.156 0 0123.976 14zM5.138 14A8.862 8.862 0 0114 5.138 8.862 8.862 0 0122.862 14c0 3.22-1.72 6.028-4.28 7.578v4.239A2.184 2.184 0 0116.397 28h-4.796a2.184 2.184 0 01-2.183-2.183v-4.24A8.845 8.845 0 015.138 14zM14 7.45A6.55 6.55 0 007.45 14a6.541 6.541 0 003.64 5.86l.64.32v5.508h4.54v-5.509l.64-.319A6.541 6.541 0 0020.55 14 6.55 6.55 0 0014 7.45z\" fill=\"#323B43\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.419 22.263h9.162v2.312H9.419v-2.312z\" fill=\"#323B43\"/></g><defs><clipPath id=\"clip0_19475_5878\"><path fill=\"#fff\" d=\"M0 0h28v28H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});