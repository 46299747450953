define("ember-svg-jar/inlined/sc-spotlight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M4.977 2.497a.393.393 0 00-.596.344v22.255c0 .313.334.506.596.343l.605 1.023-.605-1.023 17.978-11.127a.406.406 0 000-.687L4.977 2.497zM2 2.841C2 .63 4.361-.73 6.21.414l17.978 11.128c1.783 1.103 1.783 3.75 0 4.853L6.21 27.522C4.361 28.667 2 27.305 2 25.096V2.84z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});